<template>
    <div class="privacy-policy-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Website Terms and Conditions</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">
                    <div class="flex-row justify-content-center">
                        <ol>
                            <li class="section-container">
                                In terms of Section 11 of the Electronic Communications and Transactions Act 25 of 2002, as amended (“ECTA”) these Terms and Conditions (“T&C’s”) are binding and enforceable against all persons that access the Dr Remo Platform (the “Platform”) or any part thereof or make use of the Platform.
                            </li>
                            <li class="section-container">
                                If you do not agree with the T&C’s, do not accept and do not click the accept box. Leave immediately and terminate use of the Platform. Any delay in leaving and terminating use of the Platform shall be regarded as the User’s full and unconditional understanding and acceptance of these T&C’s.
                            </li>
                            <li class="section-container">DEFINITIONS AND INTERPRETATIONS
                                <ol>
                                    In these T&C’s, Privacy Policy and Data Protection Policy and Cookie Policy, unless the context indicates that some other meaning is intended:
                                    <li>“Company” means Apace Systems (PTY) LTD  with registration number: 2021/748725/07;</li>
                                    <li>“DrRemo Platform” or the “Platform” means the Online Platform located on the Website which serves as a marketplace for the wellness industry;</li>
                                    <li>“Website” means the website hosted on the domain www.drremo.co.za;</li>
                                    <li>“Practitioner” means a  medical or wellness practitioner who are part of a registered medical or wellness practice (with an associated practice number) and who is also registered on the Platform offering his/her/ its services to the User of the Platform;</li>
                                    <li>“User” means a Registered User who has completed the electronic registration for the Platform as a User who is a natural or a juristic person and utilises the Platform of the Company as listed on the Website;</li>
                                    <li>Any words indicated with inverted commas and starting with a capital letter shall bear the definition of the phrase or concept immediately preceding same as if it were included as a definition in terms of this clause;</li>
                                    <li>References herein to the singular include the plural and vice versa; and</li>
                                    <li>Hyperlinks have been used in these T&C's. The fact that some or all of the hyperlinks may be non-operational shall not play a role in the determination of the validity and interpretation of these T&C's.</li>
                                    <li>The Company will respect the privacy of the Registered Users and will take reasonable measures to protect it, as more fully detailed below.</li>
                                    <li>When registering on the Platform the Company will require that Registered Users provide it with personal information.</li>
                                    <li>Should the Registered User’s personal information change, the Registered User shall inform the Company and provide it with updates to his/her/its personal information as soon as reasonably possible to enable the Company to update such personal information.</li>
                                    <li>The Registered User may choose to provide additional personal information to the Company, in which event the Registered User agrees to provide accurate and current information, and not to impersonate or misrepresent any person or entity or falsely state or otherwise misrepresent their affiliation with anyone or anything.</li>
                                </ol>
                            </li>
                            <li class="section-container">PURPOSE AND MANNER OF PERSONAL DATA COLLECTION AND USE
                                <ol>
                                    <li>The Company collects and processes personal data in accordance with the provisions of the European Union General Data Protection Regulation (“GDPR”) and Protection of Personal Information Act, No 4 of 2013 (“POPIA”), as amended and other regulations in force in the Republic of South Africa.</li>
                                </ol>
                            </li>
                            <li class="section-container">INDEMNITY ANC COMPLAINTS
                                <ol>
                                    <li>Bookings cancelled more than 24 hours before the scheduled / reserved or booked time are refundable.</li>
                                    <li>Subject to 5.1, all payments are strictly non-refundable. The User hereby indemnifies the Company against any claims however arising including as may result from any unsatisfactory performance or experience by a Practitioner.</li>
                                    <li>The User entitled to submit a star-based performance rating in relation to the experience with the Practitioner, which shall be publicly available and visible to other Users on the Platform.</li>
                                    <li>The Company reserves the right to remove any Practitioner from the Platform subject thereto that should a Practitioner receive 3 or more negative User reviews within a 6-month period the Company reserves the right to investigate and remove the Practitioner from the Platform at its sole discretion.</li>
                                </ol>
                            </li>
                        </ol>
                    </div>

                    
                </div>

                <div class="separator-line separator-primary"></div>

                <div class="section section-basic-components">
                    <div class="d-flex flex-row justify-content-center">
                        <div class="flex-column align-self-center">
                            <card class="practitioner-terms-card text-center">
                                <h4 class="card-title"><b>Practitioner Terms</b></h4>
                                <p class="card-subtitle"><a href="/practitionerterms">Click here</a> for additional Terms and Conditions that apply to Practitioners.</p>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Card } from "@/components";

    export default {
        name: "terms-and-conditions",
        components: {
            Card
        },
        metaInfo() {
            return {
                title: "DrRemo | Terms And Conditions",
                meta: [
                    {
                        name: "description",
                        content: "Read more about our terms of service for using the DrRemo platform."
                    },
                    {
                        name: "robots",
                        content: "index, follow"
                    }
                ],
                link: [
                    {
                        rel: "canonical",
                        href: "https://dr-remo.co.za/terms"
                    }
                ]
            }
        }
    }
</script>